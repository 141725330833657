/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

// ScrollAnim
function scrollAnim (target) {
    $(target).animatescroll({
        scrollSpeed: 800,
        easing: 'easeOutExpo',
        padding: 115,
    });
}

/***
 * GERAL
 ***/
$(function() {

    // menu
    var $win = $(window),
        $menu = $('header nav'),
        $menuToggle = $('header .menuToggle');

    if ($win.width() <= 768) {
        $($menuToggle).click(function(event) {
            event.preventDefault();
            $menu.slideToggle();
            $('html').toggleClass('open-menu');
        });

        $('header nav a').click(function(event) {
            $menu.slideToggle();
            $('html').toggleClass('open-menu');
        });
    }


    if ($win.width() > 768) {
        $('.Header .nav nav ul li.search').click(function(event) {
            $('.Header .nav nav ul li.search form').toggle();
            $('.Header .nav nav ul li.search').toggleClass('open-search');
            $('.Header .nav nav ul li.search form input').focus();
        });
    }

    $win.resize(function(event) {
        $('html').removeClass('open-menu');

        if ($win.width() <= 768) {
            $($menu).css('display', 'none');
        } else {
            $($menu).css('display', 'block');
        }
    });

    // imagesLoaded
    $('body').imagesLoaded().always( function( instance ) {
        $('body').addClass('loaded');
    });

    // wow
    new WOW({
        offset: 100
    }).init();


    // owl carousel
    $('.OwlCarousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : true,
        dots            : true,
        nav             : false
    });

    $('.Top10 .owl-carousel').owlCarousel({
        loop         : true,
        margin       : 20,
        loop         : true,
        autoplay     : true,
        nav          : false,
        dots         : false,
        responsive   : {
            0:{
                stagePadding : 0,
                items:2
            },
            481:{
                stagePadding : 0,
                items:3
            },
            768:{
                stagePadding : 50,
                items:3
            },
            992:{
                stagePadding : 100,
                items:4
            },
            1200:{
                stagePadding : 150,
                items:5
            }
        }
    });

    // Galeria
    $('.popup-image').magnificPopup({
        type: 'image',
    });


    $('.popup-iframe').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });

    $('.popup-inline').magnificPopup({
        type:'inline',
        midClick: true
    });
        

    // Scrollspy
    $('body').scrollspy({
        target: 'header nav',
        offset: 300
    });

    // Scroll
    $('.scroll').on('click', function(event) {
        var data   = $(this).data('target');
        var href   = $(this).attr('href');
        var target = (data !== undefined) ? data : href;

        scrollAnim(target);
    });

    // Retornar nome da música
    setInterval(function(){ 
        $.ajax({
            url: $urlsite + '/wp-admin/admin-ajax.php?action=musica',
            type: 'GET',
            dataType: 'html',
        })
        .done(function(data){
            console.log(data);
            $('.Player-radio .musica').html(data);
        });
    }, 30000);

});